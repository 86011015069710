<template>
    <div class="detail center">
      <bread-crumb></bread-crumb>
      <article-show></article-show>
      <el-dialog :modal="true"
                 :show-close="false"
                 :visible.sync="showLoginCard">
        <login-card @closeLogDialog="closeLogin"></login-card>
      </el-dialog>
    </div>
</template>
<script>
import breadCrumb from '@/components/common/breadCrumb';
import LoginCard from "../../login/loginCard";
import ArticleShow from "@/components/articleShow";

export default {
  components: {
    ArticleShow,
    LoginCard,
    breadCrumb,
  },
  data() {
    return {
      showLoginCard: false,
    }
  },
  mounted() {
  },
  methods: {
    /**
     * 关闭登陆窗口
     */
    closeLogin() {
      this.showLoginCard = false;
    }
  }
    }
</script>

